import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';


class TrackingPurchaseOrderService {
    readTrackingPurchaseOrderQuery(){
        var query = `
            query ($startDate: String!, $endDate: String!) {
                GetReportingTrackingPurchaseOrder(start_date:$startDate, end_date:$endDate) {
                    purchase_order_id
                    purchase_order_number
                    purchase_order_date
                    project_name
                    customer_name
                    invoice_numbers
                    delivery_order_numbers
                } 
            }`;
        return query;
    }
    
    async getTrackingPurchaseOrderQuery(variables){
        var query = `
            query ($startDate: String!, $endDate: String!) {
                GetReportingTrackingPurchaseOrder(start_date:$startDate, end_date:$endDate) {
                    purchase_order_id
                    purchase_order_number
                    purchase_order_date
                    project_name
                    customer_name
                    invoice_numbers
                    delivery_order_numbers
                } 
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetReportingTrackingPurchaseOrder;
    }

    async getCustomerQuery(){
        var query = gql`
            query {
                GetContact (ContactType:"Distributor") {
                    contact_id
                    contact_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { 
                    value: result.data.GetContact[i].contact_id, 
                    label: result.data.GetContact[i].contact_name
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    trackingPurchaseOrderExcelHeaderSize(){
        var size = [
            {width: 30}, //colA
            {width: 20}, //colB
            {width: 30}, //colC
            {width: 30}, //colD
            {width: 40}, //colE
            {width: 40}, //colE
        ];

        return size;
    }

    trackingPurchaseOrderExcel(data, variables){
        const uniques = [];
        data.forEach((t) => !uniques.includes(t.customer_id) && uniques.push(t.customer_id));

        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)';

        var startIndex = 5;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'TRACKING PURCHASE ORDER',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        if(data != null){
            var row5 = {
                colA : 'No. PO',
                colB : 'Tgl PO',
                colC : 'Project',
                colD : 'Customer',
                colE : 'No. Invoice',
                colF : 'No. DO'
            };
            arrayObject.push(row5);

            for (let i= 0; i < data.length; i++) {
                var row = {
                    colA : data[i].purchase_order_number,
                    colB : { v: data[i].purchase_order_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colC : data[i].project_name,
                    colD : data[i].customer_name,
                    colE : data[i].invoice_numbers,
                    colF : data[i].delivery_order_numbers,
                };
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}


export default new TrackingPurchaseOrderService();